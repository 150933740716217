import LandingDotIcon from '../../assets/icons/LandingDotIcon'
// import LandingLineIcon from '../../assets/icons/LandingLineIcon'
import MissionImage from '../../assets/images/mission_img.png'
import './Mission.scss'

export default function Mission() {
    return (
        <div className="mission-container">
            <div className='mission-content-container'>
                <div className='mission-main-heading-container'>
                    <div className='mission-dot-heading-container'>
                        <LandingDotIcon />
                        <h1 className='mission-main-heading'>Mission</h1>
                    </div>
                    <div className='LandingLineForText' />
                </div>
                <h1 className='mission-heading'>Revolutionizing Talent Aquisition through advanced AI Solutions</h1>
                <p className='mission-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
                <p className='mission-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
                <p className='mission-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
            </div>
            <div className='mission-image-container'>
                <img src={MissionImage} alt="mission" className='mission-image' />
            </div>
        </div>
    )
}