import Button from "@mui/material/Button";

import "./WhyChooseRework.scss";

function WhyChooseRework() {
  return (
    <div className="solution_page_why_choose_rework_page">
      <div className="container">
        <div className="heading">
          <h4>
            Unlock the Potential of Your Product Enterprise with Rework AI
          </h4>
        </div>
        <div className="main_content">
          <div className="left_col">
            <div className="title">
              <h4>Cost Efficiency</h4>
            </div>
            <div className="description">
              <p>
                Reduce hiring costs and maximize ROI with Rework AI’s
                cost-effective solutions, ensuring that every recruitment effort
                drives measurable results for your product enterprise.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Enhanced Collaboration</h4>
            </div>
            <div className="description">
              <p>
                Foster collaboration and synergy within product development
                teams with Rework AI’s collaborative hiring platform,
                facilitating seamless communication and alignment.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Accelerated Innovation</h4>
            </div>
            <div className="description">
              <p>
                Fuel product innovation by hiring top talent faster and more
                effectively, enabling your enterprise to bring cutting-edge
                products to market ahead of the competition.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Scalability</h4>
            </div>
            <div className="description">
              <p>
                Scale your recruitment efforts in line with your product
                enterprise’s growth trajectory, ensuring that you have the
                talent you need to support your expanding operations.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseRework;
