import Button from "@mui/material/Button";
import './InvestInUs.scss'

export default function InvestInUs() {
    return (
        <div className="invest-in-us-container">
            <h1 className="invest-in-us-heading">Invest In Us</h1>
            <p className="invest-in-us-para">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ullam cumque voluptate enim quia
                doloremque eligendi repudiandae tempore error ipsum facilis, unde dignissimos odit
                praesentium quidem ipsa non. Vel, hic sapiente!</p>
            <Button
                variant="outlined"
                sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "136px",
                    height: "50px",
                    background: "#5C27C0",
                    fontSize: "14px",
                    textTransform: "none !important",
                    marginTop: "36px",

                    "&:hover": {
                        background: "#fff",
                        color: "#5C27C0",
                        border: "solid 1px #fff",
                    },
                }}
            >
                Contact Now
            </Button>
        </div>
    )
}