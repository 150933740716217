import Header from '../Header/Header.jsx'
import Footer from '../Footer/Footer.jsx'
import InvestInUs from './InvestInUs.jsx'
import WhyInvest from './WhyInvest.jsx'
import Investment from './Investment.jsx'
import HowToInvest from './HowToInvest.jsx'
import './style.scss'

export default function LandingPageCompanyContactUs() {
  return (
    <div className='landing-page-company-invest-in-us'>
      <Header />
      <div className='container'>
      <InvestInUs />
      </div>
      <div className='container'>
      <WhyInvest />
      </div>
      <Investment />
      <div className='container'>
      <HowToInvest />
      </div>
      <div className='container'>
        <Footer />
      </div>
    </div>
  )
}