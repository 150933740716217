import './LeadershipTeam.scss'
import JohnImage from '../../assets/images/john_img.png'
import JaneImage from '../../assets/images/jane_img.png'
import SarahImage from '../../assets/images/sarah_img.png'
import EmilyImage from '../../assets/images/emily_img.png'
import MarkImage from '../../assets/images/mark_img.png'

export default function LeadershipTeam() {
    return (
        <div className="leadership-team-container">
            <h1 className='leadership-team-heading'>Our Leadership Team</h1>
            <div className='leadership-team-cards-top-container'>
                <div className='leadership-team-card-container'>
                    <img src={JohnImage} alt="john" className='card-photo' />
                    <div className='leadership-team-card-details-container'>
                        <h1 className='leadership-team-card-name'>John Doe</h1>
                        <p className='leadership-team-card-designation'>Co-Founder & CEO</p>
                    </div>
                </div>
                <div className='leadership-team-card-container'>
                    <img src={JaneImage} alt="jane" className='card-photo' />
                    <div className='leadership-team-card-details-container'>
                        <h1 className='leadership-team-card-name'>Jane Smith</h1>
                        <p className='leadership-team-card-designation'>Co-Founder & CTO</p>
                    </div>
                </div>
                <div className='leadership-team-card-container'>
                    <img src={SarahImage} alt="sarah" className='card-photo' />
                    <div className='leadership-team-card-details-container'>
                        <h1 className='leadership-team-card-name'>Sarah Lee</h1>
                        <p className='leadership-team-card-designation'>Head of Marketing</p>
                    </div>
                </div>
            </div>
            <div className='leadership-team-cards-bottom-container'>
                <div className='leadership-team-card-container'>
                    <img src={EmilyImage} alt="emily" className='card-photo' />
                    <div className='leadership-team-card-details-container'>
                        <h1 className='leadership-team-card-name'>Emily Johnson</h1>
                        <p className='leadership-team-card-designation'>VP of Product</p>
                    </div>
                </div>
                <div className='leadership-team-card-container'>
                    <img src={MarkImage} alt="mark" className='card-photo' />
                    <div className='leadership-team-card-details-container'>
                        <h1 className='leadership-team-card-name'>Mark Davis</h1>
                        <p className='leadership-team-card-designation'>VP of Sales</p>
                    </div>
                </div>
            </div>
            <h1 className='leadership-team-heading'>Investors</h1>
            <div className='leadership-team-seperator' />
            <div className='leadership-investors-container'>
                <h1 className='leadership-investors-text left-aligned-text'>Venture Capital Fund XYZ</h1>
                <h1 className='leadership-investors-text'>Angel Investor ABC</h1>
                <h1 className='leadership-investors-text right-aligned-text'>Corporate Partner DEF</h1>
            </div>
        </div>
    )
}