import MarketImage from '../../assets/images/market.png'
import TrackRecordImage from '../../assets/images/track-record.png'
import ExperiencedTeamImage from '../../assets/images/experienced.png'
import InnovativeImage from '../../assets/images/innovative.png'
import './WhyInvest.scss'


export default function WhyInvest() {
    return (
        <div className='why-invest-container'>
            <h1 className='why-invest-heading'>Why Invest In Rework AI?</h1>
            <div className='why-invest-cards-container'>
                <div className='why-invest-card-container market-container'>
                    <img src={MarketImage} alt="market" className='why-invest-card-image' />
                    <h1 className='why-invest-card-heading'>Market Opportunity</h1>
                    <p className='why-invest-card-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia provident quam nulla,
                        quis sed distinctio in fuga illo magnam, minima odio quo explicabo aperiam quos
                    </p>
                </div>
                <div className='why-invest-card-container track-record-container'>
                    <img src={TrackRecordImage} alt="track record" className='why-invest-card-image' />
                    <h1 className='why-invest-card-heading'>Proven Track Record</h1>
                    <p className='why-invest-card-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia provident quam nulla,
                        quis sed distinctio in fuga illo magnam, minima odio quo explicabo aperiam quos
                    </p>
                </div>
                <div className='why-invest-card-container experienced-team-container'>
                    <img src={ExperiencedTeamImage} alt="experienced team" className='why-invest-card-image' />
                    <h1 className='why-invest-card-heading'>Experienced Team</h1>
                    <p className='why-invest-card-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia provident quam nulla,
                        quis sed distinctio in fuga illo magnam, minima odio quo explicabo aperiam quos
                    </p>
                </div>
                <div className='why-invest-card-container innovative-technology-container'>
                    <img src={InnovativeImage} alt="innovative technology" className='why-invest-card-image' />
                    <h1 className='why-invest-card-heading'>Innovative Technology</h1>
                    <p className='why-invest-card-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia provident quam nulla,
                        quis sed distinctio in fuga illo magnam, minima odio quo explicabo aperiam quos
                    </p>
                </div>
            </div>
        </div>
    )
}