import FairnessImage from "../../assets/images/FairnessImage.png"
import TransparencyImage from "../../assets/images/TransparencyImage.png"
import PrivacyImage from "../../assets/images/PrivacyImage.png"
import AccountabilityImage from "../../assets/images/AccountabilityImage.png"
import SafetyImage from "../../assets/images/SafetyImage.png"

import "./KeyPrinciple.scss";


function KeyPrinciple() {
  return (
    <div className="key-principle-contianer">
      <div className="key-principle-heading-contianer">
        <h1 className="key-principle-heading">Key Principles of AI Ethics</h1>
      </div>

      <div className="key-principle-items-contianer-1">
        <div className="key-principle-items-section">
          <div className="key-principle-items-image">
            <img src={FairnessImage} alt="" />
          </div>
          <h1 className="key-principle-items-heading">Fairness</h1>
          <p className="key-principle-items-para">
            Ensuring that AI systems are free from bias and discrimination, and
            provide equitable outcomes for all individuals, regardless of race,
            gender, or other protected characteristics.
          </p>
        </div>

        <div className="key-principle-items-section">
          <div className="key-principle-items-image">
            <img src={TransparencyImage} alt="" />
          </div>
          <h1 className="key-principle-items-heading">Transparency</h1>
          <p className="key-principle-items-para">
            Promoting transparency in AI algorithms and decision-making
            processes to enable stakeholders to understand how AI systems work
            and why specific decisions are made.
          </p>
        </div>

        <div className="key-principle-items-section">
          <div className="key-principle-items-image">
            <img src={PrivacyImage} alt="" />
          </div>
          <h1 className="key-principle-items-heading">Privacy</h1>
          <p className="key-principle-items-para">
            {" "}
            Protecting individuals' privacy and data rights by implementing
            robust data protection measures and respecting user consent in AI
            applications.
          </p>
        </div>
      </div>

      <div className="key-principle-items-contianer-2">
        <div className="key-principle-items-section">
          <div className="key-principle-items-image">
            <img src={AccountabilityImage} alt="" />
          </div>
          <h1 className="key-principle-items-heading">Accountability</h1>
          <p className="key-principle-items-para">
            Holding AI developers, organizations, and users accountable for the
            ethical implications of AI systems and ensuring mechanisms for
            redress and recourse in case of harm
          </p>
        </div>

        <div className="key-principle-items-section">
          <div className="key-principle-items-image">
            <img src={SafetyImage} alt="" />
          </div>
          <h1 className="key-principle-items-heading">Safety</h1>
          <p className="key-principle-items-para">
            Prioritizing the safety and well-being of individuals and society in
            AI development, deployment, and use, and mitigating risks associated
            with AI systems.
          </p>
        </div>
      </div>
    </div>
  );
}

export default KeyPrinciple;
