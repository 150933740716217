import LandingDotIcon from '../../assets/icons/LandingDotIcon'
// import LandingLineIcon from '../../assets/icons/LandingLineIcon'
import PhilosophyImage from '../../assets/images/philosophy_img.png'
import './Philosophy.scss'

export default function Philosophy() {
    return (
        <div className="philosophy-container">
            <div className='philosophy-image-container'>
                <img src={PhilosophyImage} alt="philosophy" className='philosophy-image' />

            </div>
            <div className='philosophy-content-container'>
                <div className='philosophy-main-heading-container'>
                    <div className='philosophy-dot-heading-container'>
                        <LandingDotIcon />
                        <h1 className='philosophy-main-heading'>Philosophy</h1>
                    </div>
                    <div className='LandingLineForText' />
                </div>
                <h1 className='philosophy-heading'>Ethical AI for Equitable Hiring</h1>
                <p className='philosophy-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                </p>
                <p className='philosophy-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.Duis aute irure dolor in reprehenderit in

                </p>
                <p className='philosophy-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.Duis aute irure dolor in reprehenderit in

                </p>
            </div>
        </div>
    )
}