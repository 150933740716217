import SuccessStoriesImage from "../../assets/images/SuccessStoriesImage.png";
import "./SuccessStories.scss";

function SuccessStories() {
  return (
    <div className="success-stories-continer">
      <div className="success-stories-text-continer">

        <div className="success-stories-heading-continer">
          <p className="success-stories-text-small-heading">CUSTOMER STORIES</p>
          <h1 className="success-stories-heading">
            Success Stories Of Our Customer
          </h1>
          <p className="success-stories-heading-para">
            Dive into a world of excitement and endless possibilities as you
            discover our captivating events. Uncover unique experiences and
            connect with like-minded enthusiasts today
          </p>
        </div>
        
        <div className="success-stories-image-container">
          <img
            className="success-stories-image"
            src={SuccessStoriesImage}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default SuccessStories;
