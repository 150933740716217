function Icon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3270_8812)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.9987 9.0448C4.50742 10.2656 4.23487 11.6608 4.10298 13.0094C4.69383 12.7082 5.36219 12.5465 6.03976 12.5465C8.2489 12.5465 10.0398 14.3373 10.0398 16.5465C10.0398 18.7556 8.2489 20.5465 6.03976 20.5465C3.841 20.5465 2.05659 18.7724 2.03987 16.5776C1.91407 14.3934 2.03593 11.0498 3.14331 8.29813C3.70376 6.90547 4.54284 5.59912 5.79463 4.69634C7.06675 3.77889 8.67852 3.34134 10.6462 3.55216L10.4332 5.54078C8.9009 5.37661 7.79393 5.72031 6.96451 6.31849C6.11476 6.93133 5.46948 7.87496 4.9987 9.0448ZM15.4987 9.0448C15.0074 10.2656 14.7349 11.6608 14.603 13.0094C15.1938 12.7082 15.8622 12.5465 16.5398 12.5465C18.7489 12.5465 20.5398 14.3373 20.5398 16.5465C20.5398 18.7556 18.7489 20.5465 16.5398 20.5465C14.341 20.5465 12.5566 18.7724 12.5399 16.5776C12.4141 14.3934 12.5359 11.0498 13.6433 8.29813C14.2038 6.90547 15.0428 5.59912 16.2946 4.69634C17.5667 3.77889 19.1785 3.34134 21.1462 3.55216L20.9332 5.54078C19.4009 5.37661 18.2939 5.72031 17.4645 6.31849C16.6148 6.93132 15.9695 7.87496 15.4987 9.0448Z"
          fill="#5C27C0"
        />
      </g>
      <defs>
        <clipPath id="clip0_3270_8812">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
