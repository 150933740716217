function Icon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4527 2.02018C21.435 1.71743 21.3067 1.43172 21.0923 1.21728C20.8779 1.00284 20.5922 0.874606 20.2894 0.856926C16.2057 0.616645 12.5973 1.09824 9.56441 2.28727C6.66247 3.42474 4.42569 5.18818 3.09331 7.3868C1.27625 10.3888 1.27212 14.0332 3.04691 17.5126L1.24944 19.3101C1.13432 19.4252 1.04301 19.5619 0.980715 19.7123C0.918417 19.8627 0.886353 20.0239 0.886353 20.1867C0.886353 20.3495 0.918417 20.5107 0.980715 20.6611C1.04301 20.8115 1.13432 20.9481 1.24944 21.0632C1.48192 21.2957 1.79722 21.4263 2.126 21.4263C2.28879 21.4263 2.44999 21.3943 2.60039 21.332C2.75079 21.2697 2.88745 21.1784 3.00256 21.0632L4.80003 19.2658C6.52634 20.1465 8.29391 20.5909 10.0027 20.5909C11.7388 20.5963 13.4426 20.1217 14.9259 19.2194C17.1245 17.887 18.8879 15.6492 20.0254 12.7483C21.2113 9.7133 21.6929 6.10393 21.4527 2.02018ZM13.6399 17.0991C11.5681 18.3541 9.13747 18.449 6.65628 17.4002L15.3745 8.68205C15.4896 8.56694 15.5809 8.43028 15.6432 8.27988C15.7055 8.12948 15.7376 7.96828 15.7376 7.80549C15.7376 7.6427 15.7055 7.4815 15.6432 7.3311C15.5809 7.1807 15.4896 7.04404 15.3745 6.92893C15.2594 6.81381 15.1227 6.7225 14.9723 6.66021C14.8219 6.59791 14.6607 6.56584 14.4979 6.56584C14.3351 6.56584 14.1739 6.59791 14.0235 6.66021C13.8731 6.7225 13.7365 6.81381 13.6213 6.92893L4.90934 15.6533C3.86366 13.1783 3.95853 10.7415 5.21872 8.66968C7.37506 5.10877 12.4932 3.1298 19.0375 3.2814C19.1798 9.82365 17.2008 14.9428 13.6399 17.0991Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}

export default Icon;
