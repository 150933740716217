import "./EthicalChallenges.scss";

function EthicalChallenges() {
  return (
    <div className="ethical-challenges-container">
      <div className="ethical-challenges-heading-container">
        <h1 className="ethical-challenges-heading">Ethical Challenges in AI</h1>
      </div>

      <div className="ethical-challenges-info-container">
        <div className="ethical-challenges-info-section">
          <h1 className="ethical-challenges-info-heading">
            Bias and Discrimination
          </h1>
          <p className="ethical-challenges-info-para">
            AI systems can perpetuate and amplify existing biases in data,
            leading to unfair outcomes and discriminatory practices.
          </p>
        </div>

        <div className="ethical-challenges-info-section">
          <h1 className="ethical-challenges-info-heading">Privacy Concerns</h1>
          <p className="ethical-challenges-info-para">
            AI technologies often involve the collection and analysis of
            sensitive personal data, raising concerns about surveillance, data
            misuse, and infringement of privacy rights.
          </p>
        </div>

        <div className="ethical-challenges-info-section">
          <h1 className="ethical-challenges-info-heading">
            Autonomous Decision-Making
          </h1>
          <p className="ethical-challenges-info-para">
            AI system with autonomous decision-making capabilities pose ethical
            dilemmas regarding accountability, responsibility, and the potential
            for unintended consequences.
          </p>
        </div>

        <div className="ethical-challenges-info-section">
          <h1 className="ethical-challenges-info-heading">Job Displacement</h1>
          <p className="ethical-challenges-info-para">
            The widespread adoption of AI and automation may lead to job
            displacement and socioeconomic inequalities, raising questions about
            the ethical implications of technological unemployment.
          </p>
        </div>
      </div>
    </div>
  );
}

export default EthicalChallenges;
