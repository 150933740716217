import imageOfExperience from '../../assets/images/Product Image.png'
import LeafIcon from '../../assets/icons/LeafIcon'
import LandingPageLockIcon from '../../assets/icons/LandingPageLockIcon';
import LandingPageAiProctoring from '../../assets/icons/LandingPageAiProctoring';
import LandingPageSeamless  from '../../assets/icons/LandingPageSeamless';


import './ExperienceTheFeature.scss'


function ExperienceTheFeature() {
  return (
    <>
    {/* <section className="experience_feature">
      <div className="heading">
        <h4>Experience the Future of Hiring</h4>
        <span>Watch our introductory video to see how Rework AI revolutionizes the recruitment process</span>
      </div>
      <div className='image'>
        <img src={imageOfExperience} alt=''/>
      </div>
    </section> */}
    <section className='solution_page_discover_power'>
      <div className='heading'>
        <h4>Tailored Solutions for
 Coding Education Excellence
</h4>
      </div>
      <div className='box_list'>
        <div className='box'>
          <LeafIcon/>
          <div className='title'>
            <span>Customized Mock Interviews</span>
          </div>
          <div className='description'>
            <p>Personalized mock interviews tailored to match the technical skills and industry-specific requirements of coding school students.</p>
          </div>
        </div>
        <div className='box'>
          <LandingPageAiProctoring/>
          <div className='title'>
            <span>AI-Powered Assessment</span>
          </div>
          <div className='description'>
            <p>Streamline student assessment and career readiness with AI-powered evaluation tools, providing comprehensive insights into student performance.</p>
          </div>
        </div>
        <div className='box'>
          <LandingPageLockIcon/>
          <div className='title'>
            <span>Scalable Solutions</span>
          </div>
          <div className='description'>
            <p>Flexible plans and pricing options designed to meet the evolving needs of coding schools, from startups to established institutions.</p>
          </div>
        </div>
        <div className='box'>
          <LandingPageSeamless/>
          <div className='title'>
            <span>Dedicated Support </span>
          </div>
          <div className='description'>
            <p>Access to a dedicated support team to assist coding schools in optimizing their training programs and preparing students for success in the tech industry.</p>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
export default ExperienceTheFeature;