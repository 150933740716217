function ExploreSearchIcon() {
  return (
    <svg
      width="28"
      height="30"
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5280_16137)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.3287 5.92916C15.595 3.19549 11.1628 3.19549 8.42916 5.92916C5.69549 8.66283 5.69549 13.095 8.42916 15.8287C11.1628 18.5623 15.595 18.5623 18.3287 15.8287C21.0623 13.095 21.0623 8.66283 18.3287 5.92916ZM7.01495 4.51495C10.5297 1.00023 16.2281 1.00023 19.7429 4.51495C23.0186 7.79066 23.2413 12.9632 20.4111 16.4969L23.9855 20.0713L22.5713 21.4855L18.9969 17.9111C15.4632 20.7413 10.2907 20.5186 7.01495 17.2429C3.50023 13.7281 3.50023 8.02966 7.01495 4.51495Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5280_16137"
          x="-1.5"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5280_16137"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5280_16137"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default ExploreSearchIcon;
