function Icon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.23318 2.45835C6.63854 0.863709 4.05311 0.863709 2.45847 2.45835C0.863831 4.05299 0.863831 6.63841 2.45847 8.23306C4.05311 9.8277 6.63854 9.8277 8.23318 8.23306C9.82782 6.63841 9.82782 4.05299 8.23318 2.45835ZM1.63351 1.63339C3.68377 -0.41686 7.00788 -0.41686 9.05814 1.63339C10.969 3.54422 11.0989 6.56156 9.44796 8.62288L11.533 10.7079L10.7081 11.5329L8.623 9.44783C6.56168 11.0988 3.54435 10.9688 1.63351 9.05801C-0.416738 7.00776 -0.416738 3.68364 1.63351 1.63339Z"
        fill="#4E5969"
      />
    </svg>
  );
}

export default Icon;
