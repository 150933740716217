import VideoImage from "../../assets/images/Video-image.png";
// import Frame from "../../assets/images/Frame.png";
import "./WebinarVideo.scss";

function WebinarVideo() {
  return (
    <diV className="Webinar-video-container">
      <div className="main-container">
        <div className="text-container">
          <h1 className="text-container-heading">
            Don't Miss This Awesome Webinar!
          </h1>
          <p className="text-container-paragraph">
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, the industry's standard dummy text ever since the 1500s
          </p>
        </div>
        
        <div className="video-container">
        <div className="video-frame">
            {/* <img src={Frame} alt=""/> */}
          </div>
          <img classname="video" src={VideoImage} alt="" />
        </div>
      </div>

      <div className="main-container">
        <div className="video-container">
          <div className="video-frame">
            {/* <img  src={Frame} alt="" /> */}
          </div>
          <img classname="video" src={VideoImage} alt="" />
        </div>
        <div className="text-container">
          <h1 className="text-container-heading">
            Don't Miss This Awesome Webinar!
          </h1>
          <p className="text-container-paragraph">
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, the industry's standard dummy text ever since the 1500s
          </p>
        </div>
      </div>
    </diV>
  );
}

export default WebinarVideo;
