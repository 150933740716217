function Icon() {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7834 7.74922H15.8959V5.68672C15.8959 4.2645 15.3309 2.90052 14.3252 1.89486C13.3196 0.889195 11.9556 0.324219 10.5334 0.324219C9.11116 0.324219 7.74719 0.889195 6.74153 1.89486C5.73586 2.90052 5.17089 4.2645 5.17089 5.68672V7.74922H2.28339C1.73638 7.74922 1.21177 7.96652 0.824978 8.35331C0.438185 8.7401 0.220886 9.26471 0.220886 9.81172V21.3617C0.220886 21.9087 0.438185 22.4333 0.824978 22.8201C1.21177 23.2069 1.73638 23.4242 2.28339 23.4242H18.7834C19.3304 23.4242 19.855 23.2069 20.2418 22.8201C20.6286 22.4333 20.8459 21.9087 20.8459 21.3617V9.81172C20.8459 9.26471 20.6286 8.7401 20.2418 8.35331C19.855 7.96652 19.3304 7.74922 18.7834 7.74922ZM7.64589 5.68672C7.64589 4.92091 7.9501 4.18646 8.49162 3.64495C9.03313 3.10344 9.76757 2.79922 10.5334 2.79922C11.2992 2.79922 12.0336 3.10344 12.5752 3.64495C13.1167 4.18646 13.4209 4.92091 13.4209 5.68672V7.74922H7.64589V5.68672ZM18.3709 20.9492H2.69589V10.2242H18.3709V20.9492ZM12.1834 15.5867C12.1834 15.9131 12.0866 16.2321 11.9053 16.5034C11.724 16.7748 11.4663 16.9862 11.1648 17.1111C10.8633 17.236 10.5316 17.2687 10.2115 17.205C9.89142 17.1413 9.59742 16.9842 9.36666 16.7534C9.1359 16.5227 8.97876 16.2287 8.91509 15.9086C8.85142 15.5885 8.8841 15.2568 9.00898 14.9553C9.13387 14.6538 9.34535 14.3961 9.6167 14.2148C9.88804 14.0335 10.207 13.9367 10.5334 13.9367C10.971 13.9367 11.3907 14.1106 11.7001 14.42C12.0095 14.7294 12.1834 15.1491 12.1834 15.5867Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}

export default Icon;
