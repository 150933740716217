// import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import ProductsPage from "./pages/ProductsPage";
import ProductsJDWizardPage from "./pages/ProductsJDWizardPage";
import ProductsCandidateScreeninPage from "./pages/ProductsCandidateScreeninPage";
import ProductsDataInsightsPage from "./pages/ProductsDataInsightsPage";
import ResourcesPage from "./pages/ResourcesPage";
import WebinarPage from "./pages/WebinarPage";
import CustomerStoriesPage from "./pages/CustomerStoriesPage";
import AIEthicsPage from "./pages/AIEthicsPage";
import CompanyAboutUs from "./pages/CompanyAboutUs/index";
import CompanyContactUs from "./pages/CompanyContactUs/index";
import CompanyInvestInUs from "./pages/CompanyInvestInUs/index";

import SolutionStartup from "./pages/SolutionStartup/index";
import SolutionProduct from "./pages/SolutionProduct/index";
import SolutionStaffing from "./pages/SolutionStaffing/index";
import SolutionServices from "./pages/SolutionServices/index";
import SolutionCoding from "./pages/SolutionCoding/index";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },

  // ************************* Solutions ******************************* //

  {
    path: "solution-startup",
    element: <SolutionStartup />,
  },
  {
    path: "solution-product",
    element: <SolutionProduct />,
  },
  {
    path: "solution-services",
    element: <SolutionServices />,
  },
  {
    path: "solution-staffing",
    element: <SolutionStaffing />,
  },
  {
    path: "solution-coding",
    element: <SolutionCoding />,
  },

  // ************************* Products ******************************* //
  {
    path: "products-ai-interview",
    element: <ProductsPage />,
  },
  {
    path: "products-jd-wizard",
    element: <ProductsJDWizardPage />,
  },
  {
    path: "products-candidate-screening",
    element: <ProductsCandidateScreeninPage />,
  },
  {
    path: "products-data-insights",
    element: <ProductsDataInsightsPage />,
  },

  // ************************* Resources ******************************* //
  {
    path: "resources",
    element: <ResourcesPage />,
  },
  {
    path: "resources-webinar",
    element: <WebinarPage />,
  },
  {
    path: "resources-customer-stories",
    element: <CustomerStoriesPage />,
  },
  {
    path: "resources-customer-aiethics",
    element: <AIEthicsPage />,
  },

  // ************************* Company ******************************* //
  {
    path: "company-about-us",
    element: <CompanyAboutUs />,
  },
  {
    path: "company-contact-us",
    element: <CompanyContactUs />,
  },
  {
    path: "company-invest-in-us",
    element: <CompanyInvestInUs />,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
