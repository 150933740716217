import Button from "@mui/material/Button";
import "./ContactUs.scss";

export default function ContactUs() {
  return (
    <div className="contact-us-container">
      <h1 className="contact-us-heading">
        Ready to Experience the Future of Recruitment?{" "}
        <span className="contact-us-heading-highlight">Contact us </span>Today
        to Learn More!
      </h1>
      <a href="https://calendly.com/reworkai/bookdemo" target="_blank" rel="noreferrer">
        <Button
          variant="outlined"
          className="contact-us-button"
          sx={{
            border: "solid 1px #5C27C0",
            color: "#fff",
            borderRadius: "8px",
            width: "160px",
            height: "50px",
            background: "#5C27C0",
            fontSize: "12px",
            textTransform: "none !important",

            "&:hover": {
              background: "#fff",
              color: "#5C27C0",
              border: "solid 1px #5C27C0",
            },
          }}
        >
          Book My Demo Now
        </Button>
      </a>
    </div>
  );
}
