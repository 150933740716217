import Comma from "../../assets/icons/Comma.jsx";
import "./Customer.scss";

function Customer() {
  return (
    <div className="customer-container">
      <div className="customer-heading-container">
        <h1 className="customer-heading">Customer love us</h1>
        <p className="customer-para">But don’t just take our word for it</p>
      </div>
      <div className="customer-info-container">
        <div className="customer-comma-1">
          <Comma />
        </div>
        <p className="customer-info-para-1">
          "Rework AI has been instrumental in helping our{" "}
          <span className="capital-text">PRODUCT ENTERPRISE</span> stay ahead of
          the curve
        </p>
        <hr className="customer-hr" />
        <p className="customer-info-para-2">
          The AI-powered{" "}
          <span className="capital-text">INTERVIEW FEATURES</span> and scalable
          solutions have enabled us to recruit{" "}
          <span className="capital-text">TOP TALENT</span> and drive{" "}
          <span className="capital-text">INNOVATION</span> within our product
          development teams
        </p>
        <div className="customer-comma-2">
          <Comma />
        </div>
      </div>
    </div>
  );
}

export default Customer;
