import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import CallToAction from "../LandingPage/CallToAction.jsx";
import Discover from "./Discover.jsx";
import WebinarVideo from "./WebinarVideo.jsx";


import "./style.scss";

function WebinarPage() {
  return (
    <div className="Webinar_page">
      <Header />
      <div className="discover-container">
        <Discover />
      </div>
      <div className="webinar-video-container">
        <WebinarVideo/>
      </div>
      <CallToAction/>
      <div className='container'>
        <Footer/>
      </div>
    </div>
  );
}
export default WebinarPage;
