function DiscoverSearchIcon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8758 14.4998H14.8883L14.5383 14.1623C16.0383 12.4123 16.8133 10.0248 16.3883 7.48731C15.8008 4.01231 12.9008 1.23731 9.40078 0.812305C4.11328 0.162305 -0.336718 4.61231 0.313282 9.89981C0.738282 13.3998 3.51328 16.2998 6.98828 16.8873C9.52578 17.3123 11.9133 16.5373 13.6633 15.0373L14.0008 15.3873V16.3748L19.3133 21.6873C19.8258 22.1998 20.6633 22.1998 21.1758 21.6873C21.6883 21.1748 21.6883 20.3373 21.1758 19.8248L15.8758 14.4998ZM8.37578 14.4998C5.26328 14.4998 2.75078 11.9873 2.75078 8.87481C2.75078 5.76231 5.26328 3.24981 8.37578 3.24981C11.4883 3.24981 14.0008 5.76231 14.0008 8.87481C14.0008 11.9873 11.4883 14.4998 8.37578 14.4998Z"
        fill="#5C27C0"
      />
    </svg>
  );
}

export default DiscoverSearchIcon;
