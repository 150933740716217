import Button from "@mui/material/Button";
import './HowToInvest.scss'

export default function HowToInvest() {
    return (
        <div className='how-to-invest-container'>
            <h1 className="how-to-invest-heading">How to Invest</h1>
            <p className="how-to-invest-para">To learn more about investment Opportunities with Rework AI, please fill out the form below or contact our
                investor Relations team at [<span className="how-to-invest-para-highlight">Investor Relations Email/Contact Information</span>]. We look forward to exploring
                potential partnerships and driving mutual growth and success. Invest with Rework AI and be a part of
                shaping the future of recruitment!
            </p>
            <form className="how-to-invest-form-container">
                <h1 className="how-to-invest-form-heading">Investment Form</h1>
                <div className="how-to-invest-form-combine-container">
                    <div className="how-to-invest-form-label-input-container">
                        <label for="fullname" className="label-container">Full Name</label>
                        <div className="how-to-invest-form-outer-input-container">
                            <input type="text" id="fullname" className="input-container" />
                        </div>
                    </div>
                    <div className="how-to-invest-form-label-input-container">
                        <label for="email" className="label-container">Email</label>
                        <div className="how-to-invest-form-outer-input-container">
                            <input type="text" id="email" className="input-container" />
                        </div>
                    </div>
                </div>
                <div className="how-to-invest-form-combine-container">
                    <div className="how-to-invest-form-label-input-container">
                        <label for="phoneNum" className="label-container">Phone Number</label>
                        <div className="how-to-invest-form-outer-input-container">
                            <input type="text" id="phoneNum" className="input-container" placeholder="+1 012 3456 789" />
                        </div>

                    </div>
                    <div className="how-to-invest-form-label-input-container">
                        <label for="investmentAmt" className="label-container">Investment Amount</label>
                        <div className="how-to-invest-form-outer-input-container">
                            <input type="text" id="investmentAmt" className="input-container" />
                        </div>

                    </div>
                </div>

                <div className="how-to-invest-form-label-input-container message-label-input-container">
                    <label for="investmentAmt" className="label-container">Message/Question</label>
                    <div className="how-to-invest-form-outer-input-container">
                        <textarea type="text" id="message" placeholder='Write your message here...' className='input-container' />
                    </div>

                </div>

                <Button
                    variant="outlined"
                    sx={{
                        border: "solid 1px #5C27C0",
                        color: "#fff",
                        borderRadius: "8px",
                        width: "136px",
                        height: "50px",
                        background: "#5C27C0",
                        fontSize: "14px",
                        textTransform: "none !important",
                        alignSelf: "flex-end",

                        "&:hover": {
                            background: "#fff",
                            color: "#5C27C0",
                            border: "solid 1px #5C27C0",
                        },
                    }}
                >
                    Submit
                </Button>
            </form>
        </div>
    )
}