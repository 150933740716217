function Icon() {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83337 8.45703C4.83337 6.45482 6.45617 4.83203 8.45837 4.83203H20.5417C22.5439 4.83203 24.1667 6.45482 24.1667 8.45703V20.5404C24.1667 22.5426 22.5439 24.1654 20.5417 24.1654H8.45837C6.45617 24.1654 4.83337 22.5426 4.83337 20.5404V8.45703ZM10.6938 21.0152V12.2862H7.77446V21.0152H10.6938ZM9.23412 11.0936C10.2515 11.0936 10.8859 10.4242 10.8859 9.58561C10.8666 8.72891 10.2515 8.07761 9.25346 8.07761C8.25417 8.07761 7.60167 8.72891 7.60167 9.58561C7.60167 10.4242 8.23483 11.0936 9.21479 11.0936H9.23412ZM15.2299 21.0152V16.1408C15.2299 15.8798 15.2492 15.62 15.3253 15.4327C15.5368 14.9119 16.0177 14.3718 16.8237 14.3718C17.881 14.3718 18.3039 15.1717 18.3039 16.3462V21.0152H21.2232V16.0103C21.2232 13.329 19.7829 12.0808 17.8616 12.0808C16.3125 12.0808 15.6177 12.9267 15.2299 13.5224V12.2862H12.3093C12.348 13.1019 12.3105 20.9657 12.3093 21.0164L15.2299 21.0152Z"
        fill="#AAAAAA"
      />
    </svg>
  );
}

export default Icon;
