
import './AIEthicsCallToAction.scss'


function AIEthicsCallToAction() {
  return (
    <div className="ai_ethics_call_to_action">
      <div className="heading">
        <h1>Committed to Ethical AI? </h1>
        <p className='para'>Learn How Our AI Solutions Prioritize Ethics and Responsible AI.</p>
      </div>
    </div>
  )
}
export default AIEthicsCallToAction