import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import NewReworkFullLogo from "../../assets/icons/NewReworkFullLogo";
import Button from "@mui/material/Button";
import DownArrowIcon from "../../assets/icons/DownArrowIcon";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.scss";

function Header() {
  const [menuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);

  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  return (
    <section className="lading_header">
      <div className="version">
        <p>
          The wait is over! Experience the website’s remarkable upgrade,
          <span>&nbsp; Version 2.0</span>
        </p>
      </div>
      <div className="container">
        <div className="inner_lading_header">
          <Link to="/">
            <div className="logo">
              <NewReworkFullLogo />
            </div>
          </Link>
          <div className="lading_header_right">
            <button
              className="menu_icon"
              onClick={() => setMenuVisible(!menuVisible)}
            >
              <MenuIcon />
            </button>
            <div
              className={`lading_header_right_inner ${
                menuVisible ? "menu_active" : ""
              }`}
            >
              <nav className="navigation">
                <ul>
                  <li>
                    <Button
                    id="solutions-button"
                    aria-controls={currentMenu === 'solutions' ? 'solutions-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={currentMenu === 'solutions' ? 'true' : undefined}
                    onClick={(e) => handleClick(e, 'solutions')}
                    endIcon={<DownArrowIcon sx={{ color: 'white'}} />}
                    sx={{
                      color: 'white',
                      textTransform: 'none',
                      fontSize: '14px',
                    }}
                  >
                    Solutions
                  </Button>
                  <Menu
                    id="solutions-menu"
                    MenuListProps={{
                      'aria-labelledby': 'solutions-button',
                    }}
                    anchorEl={anchorEl}
                    open={currentMenu === 'solutions'}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem component={Link} to="/solution-startup" onClick={handleClose}>Startup Page</MenuItem>
                    <MenuItem component={Link} to="/solution-product" onClick={handleClose}>Product Enterprises</MenuItem>
                    <MenuItem component={Link} to="/solution-services" onClick={handleClose}>IT Services</MenuItem>
                    <MenuItem component={Link} to="/solution-staffing" onClick={handleClose}>IT Staffing</MenuItem>
                    <MenuItem component={Link} to="/solution-coding" onClick={handleClose}>Coding Schools</MenuItem>
                  </Menu>
                  </li>
                  
                  <li>
                     <Button
                    id="products-button"
                    aria-controls={currentMenu === 'products' ? 'products-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={currentMenu === 'products' ? 'true' : undefined}
                    onClick={(e) => handleClick(e, 'products')}
                    endIcon={<DownArrowIcon sx={{ color: 'white'}} />}
                    sx={{
                      color: 'white',
                      textTransform: 'none',
                      fontSize: '14px'
                    }}
                  >
                    Products
                  </Button>
                  <Menu
                    id="products-menu"
                    MenuListProps={{
                      'aria-labelledby': 'products-button',
                    }}
                    anchorEl={anchorEl}
                    open={currentMenu === 'products'}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem component={Link} to="/products-ai-interview" onClick={handleClose}>AI Interview</MenuItem>
                    <MenuItem component={Link} to="/products-jd-wizard" onClick={handleClose}>JD Wizard</MenuItem>
                    <MenuItem component={Link} to="/products-candidate-screening" onClick={handleClose}>Candidate Screening suite</MenuItem>
                    <MenuItem component={Link} to="/products-data-insights" onClick={handleClose}>Data Insights Dashboard</MenuItem>
                  </Menu>
                  </li>
                 
                  <li>
                    <Button
                    id="resources-button"
                    aria-controls={currentMenu === 'resources' ? 'resources-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={currentMenu === 'resources' ? 'true' : undefined}
                    onClick={(e) => handleClick(e, 'resources')}
                    endIcon={<DownArrowIcon sx={{ color: 'white'}} />}
                    sx={{
                      color: 'white',
                      textTransform: 'none',
                      fontSize: '14px'
                    }}
                  >
                    Resources
                  </Button>
                  <Menu
                    id="resources-menu"
                    MenuListProps={{
                      'aria-labelledby': 'resources-button',
                    }}
                    anchorEl={anchorEl}
                    open={currentMenu === 'resources'}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem component={Link} to="/resources" onClick={handleClose}>Blogs</MenuItem>
                    <MenuItem component={Link} to="/resources-webinar" onClick={handleClose}>Webinar</MenuItem>
                    <MenuItem component={Link} to="/resources-customer-stories" onClick={handleClose}>Customer Stories</MenuItem>
                    <MenuItem component={Link} to="/resources-customer-aiethics" onClick={handleClose}>AI Ethics</MenuItem>
                  </Menu>
                  </li>
                  
                  <li>
                    <Button
                    id="integration-button"
                    aria-controls={currentMenu === 'integration' ? 'integration-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={currentMenu === 'integration' ? 'true' : undefined}
                    onClick={(e) => handleClick(e, 'integration')}
                    endIcon={<DownArrowIcon sx={{ color: 'white'}} />}
                    sx={{
                      color: 'white',
                      textTransform: 'none',
                      fontSize: '14px'
                    }}
                  >
                    Integration
                  </Button>
                  <Menu
                    id="integration-menu"
                    MenuListProps={{
                      'aria-labelledby': 'integration-button',
                    }}
                    anchorEl={anchorEl}
                    open={currentMenu === 'integration'}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem component={Link} to="/" onClick={handleClose}>About</MenuItem>
                  </Menu>
                  </li>
                  
                  <li>
                    <Button
                    id="company-button"
                    aria-controls={currentMenu === 'company' ? 'company-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={currentMenu === 'company' ? 'true' : undefined}
                    onClick={(e) => handleClick(e, 'company')}
                    endIcon={<DownArrowIcon sx={{ color: 'white'}} />}
                    sx={{
                      color: 'white',
                      textTransform: 'none',
                      fontSize: '14px'
                    }}
                  >
                    Company
                  </Button>
                  <Menu
                    id="company-menu"
                    MenuListProps={{
                      'aria-labelledby': 'company-button',
                    }}
                    anchorEl={anchorEl}
                    open={currentMenu === 'company'}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem component={Link} to="/company-about-us" onClick={handleClose}>About Us</MenuItem>
                    <MenuItem component={Link} to="/company-contact-us" onClick={handleClose}>Contact Us</MenuItem>
                    <MenuItem component={Link} to="/company-invest-in-us" onClick={handleClose}>Invest in us</MenuItem>
                  </Menu>
                  </li>
                  

                </ul>
              </nav>
              <div className="header_btn">
                <a
                  href="https://app.rework.club/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="outlined"
                    sx={{
                      border: "solid 1px #5C27C0",
                      color: "#fff",
                      borderRadius: "2px",
                      width: "72px",
                      height: "34px",
                      background: "#5C27C0",
                      fontSize: "12px !important",
                      textTransform: "none !important",
                      fontWeight: "400 !important",
                      "&:hover": {
                        background: "#fff",
                        color: "#5C27C0",
                        border: "solid 1px #5C27C0",
                      },
                    }}
                  >
                    Login
                  </Button>
                </a>
                <a
                  href="https://calendly.com/reworkai/bookdemo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    variant="outlined"
                    sx={{
                      border: "solid 1px #5C27C0",
                      color: "#fff",
                      borderRadius: "2px",
                      width: "150px",
                      height: "34px",
                      background: "#5C27C0",
                      fontSize: "12px !important",
                      textTransform: "none !important",

                    fontWeight: "400 !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Free Demo
                </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
