import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import CallToAction from "../LandingPage/CallToAction.jsx";
import ExploreCustomerStories from "./ExploreCustomerStories.jsx";
import Customer from "./Customer.jsx";


import "./style.scss";
import SuccessStories from "./SuccessStories.jsx";

function CustomerStoriesPage() {
  return (
    <div className="customer_stories_page">
      <Header />
      <div className="success-stories-container">
        <SuccessStories/>
      </div>
      <div className="explore-stories-container">
        <ExploreCustomerStories/>
      </div>
      <div className="customer-container">
        <Customer/>
      </div>
      <CallToAction/>
      <div className='container'>
        <Footer/>
      </div>
    </div>
  );
}
export default CustomerStoriesPage;
