import ExploreCardImage1 from "../../assets/images/ExploreCardImage1.png"
import ExploreCardImage2 from "../../assets/images/ExploreCardImage2.png"
import ExploreCardImage3 from "../../assets/images/ExploreCardImage3.png"
import ExploreCardArrow from "../../assets/icons/ExploreCardArrow.jsx"

import "./ExploreCustomerStories.scss"

function ExploreCustomerStories(){
    return(
        <div className="explore_stories_continer">
            <h1 className="explore-stories-heading">Explore Customer Stories</h1>
            <div className="explore-stories-view-btn-continer">
                <button className="explore-stories-view-btn">View More</button>
            </div>

            <div className="explore-stories-card-continer">
                <div className="explore-stories-card">
                    <img className="explore-stories-card-image" src={ExploreCardImage1} alt=""/>
                    <div className="explore-stories-card-text-container">
                        <h1 className="explore-stories-card-heading">ProductCo</h1>
                        <p className="explore-stories-card-para"> <span className="explore-stories-card-span">“</span> Rework AI has been instrumental in helping our product enterprise stay ahead of the curve. <span className="explore-stories-card-span">“</span></p>
                    </div>
                    
                    <button className="explore-stories-card-btn">
                        Read Story
                        <div className="card-arrow">
                            <ExploreCardArrow/>
                        </div>
                        
                    </button>
                </div>

                <div className="explore-stories-card">
                    <img className="explore-stories-card-image" src={ExploreCardImage2} alt=""/>
                    <div className="explore-stories-card-text-container">
                        <h1 className="explore-stories-card-heading">ProductCo</h1>
                        <p className="explore-stories-card-para"> <span className="explore-stories-card-span">“</span> Rework AI has been instrumental in helping our product enterprise stay ahead of the curve. <span className="explore-stories-card-span">“</span></p>
                    </div>
                    
                    <button className="explore-stories-card-btn">
                        Read Story
                        <div className="card-arrow">
                            <ExploreCardArrow/>
                        </div>
                        
                    </button>
                </div>

                <div className="explore-stories-card">
                    <img className="explore-stories-card-image" src={ExploreCardImage3} alt=""/>
                    <div className="explore-stories-card-text-container">
                        <h1 className="explore-stories-card-heading">ProductCo</h1>
                        <p className="explore-stories-card-para"> <span className="explore-stories-card-span">“</span> Rework AI has been instrumental in helping our product enterprise stay ahead of the curve. <span className="explore-stories-card-span">“</span></p>
                    </div>
                    
                    <button className="explore-stories-card-btn">
                        Read Story
                        <div className="card-arrow">
                            <ExploreCardArrow/>
                        </div>
                        
                    </button>
                </div>
            </div>
        </div>

    )
}

export default ExploreCustomerStories;