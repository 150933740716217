import CompanyCalenderIcon from '../../assets/icons/CompanyCalenderIcon'
import CompanyEmailIcon from '../../assets/icons/CompanyEmailIcon'
import CompanyPhoneIcon from '../../assets/icons/CompanyPhoneIcon'
import CompanyLinkIcon from '../../assets/icons/CompanyLinkIcon'
import Button from "@mui/material/Button";
import './SendQuery.scss'

export default function SendQuery() {
    return (
        <div className="send-query-container">
            <div className='send-query-left-right-container'>
                <div className='send-query-left-container'>
                    <h1 className='send-query-medium-text'>Get in Touch with Us</h1>
                    <h1 className='send-query-large-text'>Have a Question or Inquiry?</h1>
                    <p className='send-query-small-text'>We're Here to Help!</p>
                    <div className='send-query-icon-text-container'>
                        <CompanyCalenderIcon
                        //  className = "send-query-icon"
                        />
                        <p className='send-query-icon-text-container-text'>Schedule a Call</p>
                        <CompanyLinkIcon />
                    </div>
                    <div className='send-query-icon-text-container'>
                        <CompanyEmailIcon
                        //  className = "send-query-icon"
                        />
                        <p className='send-query-icon-text-container-text'>Send Mail</p>
                        <CompanyLinkIcon />
                    </div>
                    <div className='send-query-call-us-container'>
                        <div className='send-query-icon-text-container'>
                            <CompanyPhoneIcon sx={{ height: "30px", width: "30px" }} />
                            <p className='send-query-icon-text-container-text'>Call Us</p>
                        </div>
                        <p className='send-query-call-us-number-text'>+91-1234567890</p>
                        <p className='send-query-call-us-number-text'>+91-1234567890</p>
                    </div>

                </div>
                <div className='send-query-right-container'>
                    <div className='send-query-form-container'>
                        <h1 className='send-query-form-heading'>Send a Query.</h1>
                        <input type="text" placeholder='Name' className='input-container' />
                        <input type="text" placeholder='Email' className='input-container' />
                        <textarea type="text" placeholder='Write your query here...' className='input-container text-area-container' />
                        <Button
                            variant="outlined"
                            sx={{
                                border: "solid 1px #5C27C0",
                                color: "#fff",
                                borderRadius: "8px",
                                width: "95px",
                                height: "38px",
                                background: "#5C27C0",
                                fontSize: "16px",
                                fontWeight: "400",
                                textTransform: "none !important",

                                "&:hover": {
                                    background: "#fff",
                                    color: "#5C27C0",
                                    border: "solid 1px #5C27C0",
                                },
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>


        </div>
    )
}