import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import AboutUsBanner from './AboutUsBanner'
import Mission from './Mission'
import Philosophy from './Philosophy'
import Vision from './Vision'
import LeadershipTeam from './LeadershipTeam'
import ContactUs from './ContactUs'

import './style.scss'

function LandingPageCompany() {
  return (
    <div className='landing-page-company-about-us'>
      <Header />
      <AboutUsBanner />
      <Mission />
      <div className='container'>
        <Philosophy />
      </div>
      <Vision />
      <div className='container'>
        <LeadershipTeam />
      </div>
      <ContactUs />
      <div className='container'>
        <Footer />
      </div>

    </div>
  )
}
export default LandingPageCompany