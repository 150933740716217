function Icon() {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6298 8.10375C25.5268 7.70054 25.3293 7.32761 25.0538 7.01573C24.7782 6.70384 24.4324 6.46195 24.045 6.31C20.2956 4.86188 14.3281 4.875 14 4.875C13.6719 4.875 7.70437 4.86188 3.955 6.31C3.56755 6.46195 3.22179 6.70384 2.94623 7.01573C2.67067 7.32761 2.47322 7.70054 2.37016 8.10375C2.08688 9.19532 1.75 11.1903 1.75 14.5C1.75 17.8097 2.08688 19.8047 2.37016 20.8963C2.47306 21.2997 2.67045 21.6728 2.94601 21.9849C3.22158 22.297 3.56743 22.539 3.955 22.6911C7.54688 24.0769 13.1687 24.125 13.9278 24.125H14.0722C14.8313 24.125 20.4564 24.0769 24.045 22.6911C24.4326 22.539 24.7784 22.297 25.054 21.9849C25.3296 21.6728 25.5269 21.2997 25.6298 20.8963C25.9131 19.8025 26.25 17.8097 26.25 14.5C26.25 11.1903 25.9131 9.19532 25.6298 8.10375ZM17.7428 14.8642L12.4928 18.3642C12.4269 18.4082 12.3503 18.4334 12.2712 18.4373C12.192 18.4411 12.1134 18.4234 12.0435 18.386C11.9737 18.3486 11.9153 18.2929 11.8746 18.225C11.8339 18.157 11.8124 18.0792 11.8125 18V11C11.8124 10.9208 11.8339 10.843 11.8746 10.775C11.9153 10.7071 11.9737 10.6514 12.0435 10.614C12.1134 10.5766 12.192 10.5589 12.2712 10.5627C12.3503 10.5666 12.4269 10.5918 12.4928 10.6358L17.7428 14.1358C17.8028 14.1757 17.852 14.2299 17.8861 14.2934C17.9201 14.357 17.9379 14.4279 17.9379 14.5C17.9379 14.5721 17.9201 14.6431 17.8861 14.7066C17.852 14.7701 17.8028 14.8243 17.7428 14.8642Z"
        fill="#AAAAAA"
      />
    </svg>
  );
}

export default Icon;
