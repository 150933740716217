import Button from "@mui/material/Button";

import "./WhyChooseRework.scss";

function WhyChooseRework() {
  return (
    <div className="solution_page_why_choose_rework_page">
      <div className="container">
        <div className="heading">
          <h4>
            Unlock the Potential of Your IT Staffing Agency with Rework AI
          </h4>
        </div>
        <div className="main_content">
          <div className="left_col">
            <div className="title">
              <h4>Enhanced Candidate Placement</h4>
            </div>
            <div className="description">
              <p>
                Facilitate seamless candidate placement and onboarding processes
                with Rework AI's collaborative hiring platform, ensuring a
                positive experience for both candidates and clients.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Scalability</h4>
            </div>
            <div className="description">
              <p>
                Scale your recruitment efforts in line with your IT staffing
                agency's growth trajectory, ensuring that you have the resources
                you need to support your expanding client base and placement
                volume.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Cost Efficiency</h4>
            </div>
            <div className="description">
              <p>
                Reduce candidate sourcing costs and maximize ROI with Rework
                AI's cost-effective solutions, ensuring that every placement
                effort drives measurable results for your IT staffing agency.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Increased Placement Rates</h4>
            </div>
            <div className="description">
              <p>
                Improve placement rates and client satisfaction by leveraging
                Rework AI's innovative candidate assessment and matching
                capabilities.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseRework;
