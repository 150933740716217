import Button from "@mui/material/Button";

import "./WhyChooseRework.scss";

function WhyChooseRework() {
  return (
    <div className="solution_page_why_choose_rework_page">
      <div className="container">
        <div className="heading">
          <h4>Unlock the Potential of Your IT Services with Rework AI</h4>
        </div>
        <div className="main_content">
          <div className="left_col">
            <div className="title">
              <h4>Client Satisfaction</h4>
            </div>
            <div className="description">
              <p>
                Improve client satisfaction and project outcomes by assembling
                high-performing teams with Rework AI’s collaborative hiring
                platform, facilitating seamless collaboration and project
                execution.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Cost Savings</h4>
            </div>
            <div className="description">
              <p>
                Reduce hiring costs and maximize ROI with Rework AI’s
                cost-effective solutions, ensuring that every recruitment effort
                drives measurable results for your IT services organization.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Scalability</h4>
            </div>
            <div className="description">
              <p>
                Scale your recruitment efforts in line with your IT services
                organization’s growth trajectory, ensuring that you have the
                talent you need to support your expanding client base and
                project portfolio.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Improved Efficiency</h4>
            </div>
            <div className="description">
              <p>
                Enhance recruitment efficiency and project delivery timelines by
                hiring top talent faster and more effectively with Rework AI’s
                innovative solutions.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseRework;
