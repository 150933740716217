function Comma() {
  return (
    <svg
      width="32"
      height="22"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.096 21.88H0.304L9.52 0.0879974H15.76L10.096 21.88ZM25.648 21.88H15.856L25.072 0.0879974H31.312L25.648 21.88Z"
        fill="#9747FF"
      />
    </svg>
  );
}

export default Comma;
