import "./ExploreOurBlogs.scss";
import {
  Autocomplete,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "../../assets/icons/SearchIcon.jsx";
import ExploreSearchIcon from "../../assets/icons/ExploreSearchIcon.jsx";

function Explore() {
  const options = [{ label: "Option 1" }, { label: "Option 2" }];
  return (
    <>
      <div className="explore-our-blogs-continer">
        <h1 className="explore-heading">Explore Our Blogs</h1>
        <p className="explore-text">
          Welcome to Our Blogs, your hub for insightful articles, creative
          musings, and captivating narratives.{" "}
        </p>
        <div className="explore-our-blogs-search-continer">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{
              width: 511,
              borderRadius: "10px",
              background: "#F5F5F5",
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <MenuItem
                {...props}
                sx={{
                  "&&:hover": {
                    backgroundColor: "#F8F4FF",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    "&&": {
                      minWidth: "0px",
                      marginRight: "12px",
                    },
                  }}
                >
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary={option.label} />
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                InputProps={{
                  ...params.InputProps,
                  style: { paddingRight: "16px" },
                  endAdornment: (
                    <InputAdornment position="end" style={{ zIndex: 1 }}>
                      <div
                        style={{
                          backgroundColor: "#5C27C0",
                          width: "70px",
                          height: "100%",
                          position: "absolute",
                          right: 0,
                          top: 0,
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <ExploreSearchIcon style={{ zIndex: 2 }} />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}

export default Explore;
