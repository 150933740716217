import "./Conclusion.scss";

function Conclusion() {
  return (
    <div className="conclusion-container">
      <h1 className="conclusion-heading">Conclusion</h1>

      <div className="conclusion-para-container">
        <p className="conclusion-para">
          As AI continues to evolve and permeate all aspects of society, it is
          imperative to prioritize ethical considerations in AI development and
          deployment.
        </p>

        <p className="conclusion-para">
          By adhering to principles of fairness, transparency, privacy,
          accountability, and safety, we can ensure that AI technologies serve
          the common good and contribute to a more inclusive and equitable
          future.
        </p>
      </div>
    </div>
  );
}

export default Conclusion;
