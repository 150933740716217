import { useRef } from "react";
import Colon from "../../assets/icons/LandingPageColon";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import  prevIcon from "../../assets/images/previcon.png";
import nextIcon from '../../assets/images/nexticon.png'
import tiIcon from '../../assets/images/ti_icon.png'

import "./Testimonial.scss";

function Testimonial() {
  const slickSlider = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const gotoNext = () => {
    console.log("slickSlider", slickSlider);
    slickSlider.current.slickNext();
  };

  const gotoPrev = () => {
    slickSlider.current.slickPrev();
  };

  return (
    <section className="jd_wizard_page_testimonial">
      <div className="testimonial_inner">
        <div className="heading">
          <h1>Trusted by Leading Companies</h1>
          <p>
            Join the ranks of companies that have revolutionized their hiring
            process with AI Interview:
          </p>
        </div>
        <div className="slider_section">
          <Slider {...settings} ref={slickSlider}>
            <div>
              <div className="slider_col">
                <div className="slider_box">
                  <Colon />
                  <p>
                    Reduced time-to-hire by 50% and improved candidate quality
                    with AI Interview.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="slider_col">
                <div className="slider_box">
                  <Colon />
                  <p>
                    Reduced time-to-hire by 50% and improved candidate quality
                    with AI Interview.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="slider_col">
                <div className="slider_box">
                  <Colon />
                  <p>
                    Reduced time-to-hire by 50% and improved candidate quality
                    with AI Interview.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="slider_col">
                <div className="slider_box">
                  <Colon />
                  <p>
                    Reduced time-to-hire by 50% and improved candidate quality
                    with AI Interview.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
          <div className="slider_bottom">
            <div className="ti_icon">
              <img src={tiIcon} alt=""/>
            </div>
            <div className="arrows_btn">
              <button className="prev_btn" onClick={gotoPrev}>
                <img src={prevIcon} alt=""/>
              </button>
              <h3>Tech Innovations Inc.</h3>
              <button className="next_btn" onClick={gotoNext}>
                <img src={nextIcon} alt=""/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
