function ExploreCardArrow() {
  return (
    <svg
      width="36"
      height="25"
      viewBox="0 0 36 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.4891 13.5583C36.1092 12.9382 36.1092 11.9329 35.4891 11.3128L25.3847 1.20837C24.7646 0.588314 23.7593 0.588314 23.1392 1.20837C22.5192 1.82843 22.5192 2.83375 23.1392 3.45381L32.121 12.4355L23.1392 21.4173C22.5192 22.0373 22.5192 23.0427 23.1392 23.6627C23.7593 24.2828 24.7646 24.2828 25.3847 23.6627L35.4891 13.5583ZM0.494141 14.0233H34.3664V10.8478H0.494141V14.0233Z"
        fill="white"
      />
    </svg>
  );
}

export default ExploreCardArrow;
