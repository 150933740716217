import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import SendQuery from './SendQuery'
import ReachOut from './ReachOut'
import './style.scss'


export default function LandingPageCompanyContactUs() {
  return (
    <div className='landing-page-company-contact-us'>
      <Header />
      <div className='container'>
      <SendQuery />
      </div>
      {/* <div className='company-section-container'> */}
        <ReachOut />
      {/* </div> */}
      <div className='container'>
        <Footer />
      </div>
    </div>
  )
}