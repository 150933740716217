function Icon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 2.75H7.5C6.10807 2.75149 4.77358 3.30509 3.78933 4.28933C2.80509 5.27358 2.25149 6.60807 2.25 8V17C2.25149 18.3919 2.80509 19.7264 3.78933 20.7107C4.77358 21.6949 6.10807 22.2485 7.5 22.25H16.5C17.8919 22.2485 19.2264 21.6949 20.2107 20.7107C21.1949 19.7264 21.7485 18.3919 21.75 17V8C21.7485 6.60807 21.1949 5.27358 20.2107 4.28933C19.2264 3.30509 17.8919 2.75149 16.5 2.75ZM12 17C11.11 17 10.24 16.7361 9.49993 16.2416C8.75991 15.7471 8.18314 15.0443 7.84254 14.2221C7.50195 13.3998 7.41283 12.495 7.58647 11.6221C7.7601 10.7492 8.18868 9.94736 8.81802 9.31802C9.44736 8.68868 10.2492 8.2601 11.1221 8.08647C11.995 7.91283 12.8998 8.00195 13.7221 8.34254C14.5443 8.68314 15.2471 9.25991 15.7416 9.99993C16.2361 10.74 16.5 11.61 16.5 12.5C16.4988 13.6931 16.0243 14.837 15.1806 15.6806C14.337 16.5243 13.1931 16.9988 12 17ZM17.625 8C17.4025 8 17.185 7.93402 17 7.8104C16.815 7.68679 16.6708 7.51109 16.5856 7.30552C16.5005 7.09995 16.4782 6.87375 16.5216 6.65552C16.565 6.43729 16.6722 6.23684 16.8295 6.0795C16.9868 5.92217 17.1873 5.81502 17.4055 5.77162C17.6238 5.72821 17.85 5.75049 18.0555 5.83564C18.2611 5.92078 18.4368 6.06498 18.5604 6.24998C18.684 6.43499 18.75 6.6525 18.75 6.875C18.75 7.17337 18.6315 7.45952 18.4205 7.6705C18.2095 7.88147 17.9234 8 17.625 8ZM15 12.5C15 13.0933 14.8241 13.6734 14.4944 14.1667C14.1648 14.6601 13.6962 15.0446 13.1481 15.2716C12.5999 15.4987 11.9967 15.5581 11.4147 15.4424C10.8328 15.3266 10.2982 15.0409 9.87868 14.6213C9.45912 14.2018 9.1734 13.6672 9.05764 13.0853C8.94189 12.5033 9.0013 11.9001 9.22836 11.3519C9.45542 10.8038 9.83994 10.3352 10.3333 10.0056C10.8266 9.67595 11.4067 9.5 12 9.5C12.7956 9.5 13.5587 9.81607 14.1213 10.3787C14.6839 10.9413 15 11.7044 15 12.5Z"
        fill="#AAAAAA"
      />
    </svg>
  );
}

export default Icon;
