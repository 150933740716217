import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import CallToAction from "../LandingPage/CallToAction.jsx";
import Explore from "./ExploreOurBlogs.jsx";
import ResourceCardContainer from "./ResourceCardContainer.jsx";
import Button from "@mui/material/Button";

import "./style.scss";

function ResourcesPage() {
  return (
    <div className="resource_page">
      <Header />
      <div className="explore-container">
        <Explore />
      </div>
      <div className="resource-card-container">
        <ResourceCardContainer />
      </div>
      <div className="View-more-btn">
        <Button
          variant="outlined"
          sx={{
            border: "solid 1px #D6D6D6",
            color: "#000000",
            marginTop: "14px",
            borderRadius: "4px",
            width: "239px",
            height: "56px",
            fontSize: "24px",
            fontWeight: "400",
            textTransform: "capitalize",
            background: "#D6D6D6",
            "&:hover": {
              background: "#D6D6D6",
              color: "000000",
              border: "solid 1px #000000",
            },
          }}
        >
          View More
        </Button>
      </div>
      <CallToAction/>
      <div className='container'>
        <Footer/>
      </div>
    </div>
  );
}
export default ResourcesPage;
