import LandingDotIcon from '../../assets/icons/LandingDotIcon'
// import LandingLineIcon from '../../assets/icons/LandingLineIcon'
import VisionImage from '../../assets/images/vision_img.png'
import './Vision.scss'

export default function Vision() {
    return (
        <div className="vision-container">
            <div className='vision-content-container'>
                <div className='vision-main-heading-container'>
                    <div className='vision-dot-heading-container'>
                        <LandingDotIcon />
                        <h1 className='vision-main-heading'>Vision</h1>
                    </div>
                    <div className='LandingLineForText' />

                </div>
                <h1 className='vision-heading'>Shaping the Future of Talent Aquisition</h1>
                <p className='vision-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
                <p className='vision-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
                <p className='vision-para'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                </p>
            </div>
            <div className='vision-image-container'>
                <img src={VisionImage} alt="vision" className='vision-image' />
            </div>
        </div>
    )
}