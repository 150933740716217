import "./ResourceCardContainer.scss";
import ResourceCard from "./ResourceCard";

function ResourceCardContainer() {
  return (
    <div className="resource-card-container">
      <ResourceCard />
      <ResourceCard />
      <ResourceCard />
      <ResourceCard />
      <ResourceCard />
      <ResourceCard />
    </div>
    
  );
}

export default ResourceCardContainer;
