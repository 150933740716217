import Button from "@mui/material/Button";

import './WhyChooseRework.scss'


function WhyChooseRework() {
  return (
    <div className="jd_wizard_page_why_choose_rework_page">
      <div className='container'>
        <div className="heading">
          <h4>Unlock the Potential of Your Startup with Rework AI</h4>
        </div>
        <div className="main_content">
          <div className="left_col">
            <div className="title">
              <h4>Competitive Advantage</h4>
            </div>
            <div className="description">
              <p>
                Stay ahead of the competition by leveraging cutting-edge
                technology to attract and retain top talent, positioning your
                startup as an employer of choice in a competitive market.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Cost Savings</h4>
            </div>
            <div className="description">
              <p>
                Minimize hiring costs and maximize ROI with our affordable and
                scalable solutions, ensuring that every dollar spent on
                recruitment drives measurable results.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Founder Focus</h4>
            </div>
            <div className="description">
              <p>
                Free up valuable time and resources to focus on building your
                startup’s core business, knowing that your hiring process is in
                capable hands with Rework AI.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Rapid Growth</h4>
            </div>
            <div className="description">
              <p>
                Accelerate your startup’s growth by hiring top talent faster and
                more efficiently, enabling you to scale your operations and
                bring your vision to life.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseRework