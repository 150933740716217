function Icon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2331 15.4201C20.3379 13.8805 19.8625 11.6705 19.8625 9.03359C19.8625 6.73616 18.9499 4.53282 17.3253 2.90828C15.7008 1.28375 13.4975 0.371094 11.2 0.371094C8.90259 0.371094 6.69925 1.28375 5.07472 2.90828C3.45018 4.53282 2.53753 6.73616 2.53753 9.03359C2.53753 11.6715 2.06419 13.8805 1.16906 15.4201C0.986406 15.7342 0.889585 16.0907 0.888318 16.454C0.887051 16.8173 0.981383 17.1745 1.16184 17.4898C1.34116 17.8053 1.60143 18.0672 1.91578 18.2485C2.23012 18.4298 2.58716 18.5239 2.95003 18.5211H6.68109C6.78342 19.6489 7.30377 20.6977 8.13993 21.4614C8.97608 22.2251 10.0676 22.6486 11.2 22.6486C12.3325 22.6486 13.424 22.2251 14.2601 21.4614C15.0963 20.6977 15.6166 19.6489 15.719 18.5211H19.45C19.8124 18.5233 20.1688 18.429 20.4825 18.2477C20.7963 18.0665 21.0561 17.8049 21.2351 17.4898C21.4164 17.175 21.5116 16.818 21.5112 16.4547C21.5109 16.0914 21.4149 15.7346 21.2331 15.4201ZM11.2 20.1711C10.7246 20.1712 10.2637 20.007 9.89533 19.7063C9.527 19.4057 9.27387 18.987 9.17878 18.5211H13.2213C13.1262 18.987 12.8731 19.4057 12.5047 19.7063C12.1364 20.007 11.6755 20.1712 11.2 20.1711ZM3.63684 16.0461C4.5495 14.1898 5.01253 11.8324 5.01253 9.03359C5.01253 7.39257 5.66442 5.81875 6.82481 4.65837C7.98519 3.49799 9.559 2.84609 11.2 2.84609C12.8411 2.84609 14.4149 3.49799 15.5753 4.65837C16.7356 5.81875 17.3875 7.39257 17.3875 9.03359C17.3875 11.8314 17.8495 14.1898 18.7622 16.0461H3.63684Z"
        fill="#F9F9F9"
      />
    </svg>
  );
}

export default Icon;
