import Button from "@mui/material/Button";
import './Investment.scss'

export default function Investment() {
    return (
        <div className='investment-container'>
            <h1 className='investment-heading'>Investment Opportunities</h1>
            <p className='investment-para'>We're currently seeking strategic investors who share our vision for the future of recruitment and are passionate 
                about leveraging technology to drive positive change. Whether you're <span className="investment-para-black-highlight">a venture capitalist, angel investor,
                    or corporate partner</span>, investing in <span className="investment-para-purple-highlight">Rework AI</span> offers the opportunity to be part of a 
                    transformative journey and shape the future of talent acquisition.
            </p>
            <Button
                variant="outlined"
                sx={{
                    border: "solid 1px #5C27C0",
                    color: "#ffffff",
                    borderRadius: "8px",
                    width: "136px",
                    height: "50px",
                    background: "#5C27C0",
                    fontSize: "14px",
                    textTransform: "none !important",
                    marginTop: "36px",

                    "&:hover": {
                        background: "#fff",
                        color: "#5C27C0",
                        border: "solid 1px #5C27C0",
                    },
                }}
            >
                Invest Now
            </Button>
        </div>
    )
}