import "./Discover.scss";
import {
  Autocomplete,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import DiscoverSearchIcon from "../../assets/icons/DiscoverSearchIcon.jsx";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "../../assets/icons/SearchIcon.jsx";


function Discover() {
  const options = [{ label: "Option 1" }, { label: "Option 2" }];
  return (
    <>
      <div className="discover-continer">
        <h1 className="discover-heading">Discover Our Events</h1>
        <p className="discover-text">
          Dive into a world of excitement and endless possibilities as you
          discover our captivating events. Uncover unique experiences and
          connect with like-minded enthusiasts today
        </p>
        <div className="discover-search-continer">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          sx={{
            width: 470,
            borderRadius: "10px",
            border: "2px solid #fff",
            background: "#000000",
            color: "#fff"
          }}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              sx={{
                "&&:hover": {
                  backgroundColor: "#F8F4FF",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  "&&": {
                    minWidth: "0px",
                    marginRight: "12px",
                  },
                }}
              >
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary={option.label} />
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Enter keyword to search"
              InputLabelProps={{
                style: { color: "#fff"  }
              }}
              InputProps={{
                ...params.InputProps,
                variant: "outlined",
                style: { paddingRight: "16px"},
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                        style={{
                          backgroundColor: "#fff",
                          width: "55px",
                          height: "100%",
                          borderRadius: "10px",
                          position: "absolute",
                          right: 0,
                          top: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px", 
                        }}
                      ><DiscoverSearchIcon/></div>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        </div>
      </div>
    </>
  );
}

export default Discover;
