import "./Introduction.scss";

function Introduction() {
  return (
    <div className="intorduction-container">
      <div className="introduction-heading-container">
        <h1 className="introduction-heading">Introduction</h1>
      </div>

      <div className="introduction-para-container">
        <p classname="introduction-para">
          Artificial Intelligence (AI) holds tremendous potential to transform
          industries, enhance efficiency, and improve lives. However, the rapid
          advancement of AI technology also raises significant ethical
          considerations.
        </p>
        <p classname="introduction-para">
          In this discussion, we explore the principles and guidelines that
          govern ethical AI development and deployment, aiming to foster trust,
          transparency, and accountability in AI systems.
        </p>
      </div>
    </div>
  );
}

export default Introduction;
