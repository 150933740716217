// import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Colon from '../../assets/icons/LandingPageColon'

import './WhatOurCustomer.scss'

function WhatOurCustomerSay() {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ],
  };

  return (
    <div className="solution_page_what_our_customer_say">
      <div className="container">
        <div className="heading">
          <h4>Success Stories from 
Product Enterprise Leaders Like You
</h4>
        </div>
        <div className="slider">
          <Slider {...settings}>
            <div>
              <div className='slider_box'>
                <div className='upper_row'>
                  <Colon/>
                  <div className='description'>
                    <p><span>Rework AI &nbsp; </span>has been instrumental in helping our product enterprise stay ahead of curve. The AI-powered interview features and scalable solutions have enabled us to recruit top talent and drive innovation within our product development teams.</p>
                  </div>
                </div>
                <div className='lower_row'>
                  <div className='name'>
                    <span>Jane Smith</span>
                  </div>
                  <div className='designation'>
                    <p>Director of Talent Acquisition, Tech Innovations Inc.</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='slider_box'>
                <div className='upper_row'>
                  <Colon/>
                  <div className='description'>
                    <p>Our partnership with <span>Rework AI, &nbsp; </span> has transformed our hiring processes. The personalized interview questions and AI video interviewing capabilities have enabled us to build high-performing teams and deliver innovative products to market faster.</p>
                  </div>
                </div>
                <div className='lower_row'>
                  <div className='name'>
                    <span>John Doe</span>
                  </div>
                  <div className='designation'>
                    <p>CEO, ProductCo</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='slider_box'>
                <div className='upper_row'>
                  <Colon/>
                  <div className='description'>
                  <p>Our partnership with <span>Rework AI, &nbsp; </span> has transformed our hiring processes. The personalized interview questions and AI video interviewing capabilities have enabled us to build high-performing teams and deliver innovative products to market faster.</p>
                  </div>
                </div>
                <div className='lower_row'>
                  <div className='name'>
                    <span>John Doe</span>
                  </div>
                  <div className='designation'>
                    <p>CEO, ProductCo</p>
                  </div>
                </div>
              </div>

            </div>
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default WhatOurCustomerSay