import Button from "@mui/material/Button";

import "./WhyChooseRework.scss";

function WhyChooseRework() {
  return (
    <div className="solution_page_why_choose_rework_page">
      <div className="container">
        <div className="heading">
          <h4>Unlock the Potential of Your Coding School with Rework AI</h4>
        </div>
        <div className="main_content">
          <div className="left_col">
            <div className="title">
              <h4>Scalability</h4>
            </div>
            <div className="description">
              <p>
                Scale your training programs in line with your coding school's
                growth trajectory, ensuring that you have the resources you need
                to support your expanding student base and program offerings.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Cost Efficiency</h4>
            </div>
            <div className="description">
              <p>
                Reduce training costs and maximize student ROI with Rework AI's
                cost-effective solutions, ensuring that every training effort
                drives measurable results for your coding school.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Industry-Relevant Training</h4>
            </div>
            <div className="description">
              <p>
                Facilitate seamless transition from coding school to the tech
                industry by providing students with hands-on experience and
                exposure to real-world recruitment practices.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
          <div className="left_col">
            <div className="title">
              <h4>Improved Student Outcomes</h4>
            </div>
            <div className="description">
              <p>
                Enhance student employability and career prospects by providing
                industry-relevant training and mock interview experiences with
                Rework AI.
              </p>
            </div>
            <div className="left_col_btn">
              <a
                href="https://calendly.com/reworkai/bookdemo"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="outlined"
                  sx={{
                    border: "solid 1px #5C27C0",
                    color: "#fff",
                    borderRadius: "8px",
                    width: "118px",
                    height: "36px",
                    background: "#5C27C0",
                    fontSize: "12px !important",

                    "&:hover": {
                      background: "#fff",
                      color: "#5C27C0",
                      border: "solid 1px #5C27C0",
                    },
                  }}
                >
                  Book Demo
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseRework;
