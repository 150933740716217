import "./Front.scss";

function Front() {
  return (
    <div className="front-container">
      <div className="front-heading-container">
        <h1 className="front-heading">
          Navigating the Intersection of Technology and Morality
        </h1>
      </div>

      <div className="front-para-container">
        <p className="front-para">
           Ensuring Responsible and Ethical AI Development and Implementation
        </p>
      </div>
    </div>
  );
}

export default Front;
