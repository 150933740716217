import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import Front from "./Front.jsx";
import Introduction from "./Introduction.jsx"
import KeyPrinciple from "./KeyPrinciple.jsx";
import EthicalChallenges from "./EthicalChallenges.jsx";
import EthicalGuidelines from "./EthicalGuidelines.jsx";
import Conclusion from "./Conclusion.jsx";
import AIEthicsCallToAction from "./AIEthicsCallToAction.jsx";

import "./style.scss";

function AIEthicsPage() {
  return (
    <div className="customer_stories_page">
      <Header />
      <div className="front-container">
        <Front/>
      </div>
      <div className="introduction-container">
        <Introduction/>
      </div>
      <div className="key-principle-contianer">
        <KeyPrinciple/>
      </div>
      <div className="ethical-challenges-contianer">
        <EthicalChallenges/>
      </div>
      <div className="ethical-guidelines-contianer">
        <EthicalGuidelines/>
      </div>
      <div className="conclusion-contianer">
        <Conclusion/>
      </div>
        <AIEthicsCallToAction/>
      <div className='container'>
        <Footer/>
      </div>
    </div>
  );
}
export default AIEthicsPage;
