import "./EthicalGuidelines.scss";

function EthicalGuidelines() {
  return (
    <div className="ethical-guidelines-container">
      <div className="ethical-guidelines-heading-container">
        <h1 className="ethical-guidelines-heading">
          Ethical Guidelines and Frameworks
        </h1>
      </div>

      <div className="ethical-guidelines-content-container">
        <div className="ethical-guidelines-card">
          <h1 className="ethical-guidelines-card-heading">
            IEEE Ethically Aligned Design
          </h1>
          <p className="ethical-guidelines-card-para">
            A framework developed by the Institute of Electrical and Electronics
            Engineers (IEEE) to guide the development of ethically aligned AI
            systems across various domains.
          </p>
        </div>

        <div className="ethical-guidelines-card">
          <h1 className="ethical-guidelines-card-heading">
            EU Ethics Guidelines for Trustworthy AI
          </h1>
          <p className="ethical-guidelines-card-para">
            Guidelines published by the European Commission to promote the
            development and use of AI systems that are lawful, ethical, and
            socially beneficial.
          </p>
        </div>

        <div className="ethical-guidelines-card">
          <h1 className="ethical-guidelines-card-heading">
            AI Ethics Principles
          </h1>
          <p className="ethical-guidelines-card-para">
            Principles established by leading tech companies, industry
            consortia, and academic institutions to guide ethical AI development
            and deployment, such as Google's AI Principles and Microsoft's AI
            for Good.
          </p>
        </div>
      </div>
    </div>
  );
}

export default EthicalGuidelines;
