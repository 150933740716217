import "./ResourceCard.scss"
import ResourceCardImage from "../../assets/images/ResourceCardImage.jpg"
import Button from "@mui/material/Button";

function ResourceCard(){
    return(
        <div className="resource-card">
            <img src={ResourceCardImage} alt=""/>
            <div className="resource-card-info-container">
                <p className="resource-card-heading">Artificial Intelligence AI can provide some features</p>
                <p className="resource-card-text">Welcome to [Your Blog Title], your hub for insightful articles, creative musings, and captivating narratives. Join us as we explore a wide range of topics and </p>
            </div>
            <div className="resource-card-btn">
                 <Button
            variant="outlined"
            sx={{
              border: "solid 1px #5C27C0",
              color: "#fff",
              marginTop: "14px",
              borderRadius: "4px",
              width: "130px",
              height: "40px",
              fontWeight: "400",
              textTransform: "capitalize",
              background: "#5C27C0",

              "&:hover": {
                background: "#fff",
                color: "#5C27C0",
                border: "solid 1px #5C27C0",
              },
            }}>
            Read More
          </Button>
            </div>
           
        </div>
    )
}

export default ResourceCard